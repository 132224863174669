import React, { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server'; // Import for rendering the email template to HTML
// Import the EmailTemplate component
import EmailTemplate from './EmailTemplate.tsx';

import {
  Button,
  Snackbar,
  Alert,
  Modal,
  Box,
  TextField,
  Typography,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  bgcolor: '#282f34',
  border: '1px solid #424242',
  boxShadow: 24,
  p: 4,
  width: { xs: "80%", md: "50%", lg: "50%" },
};


const ModalForm = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '', // Added field for textarea
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // can be 'success', 'error', 'info', 'warning'
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const emailHtml = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate
          name={formData.name}
          email={formData.email}
          message={formData.message}
        />
      );

      const response = await axios.post('http://localhost:3001/send-email', {
        to: formData.email, // Sending the email to the recipient entered in the form
        subject: `New message from ${formData.name}`,
        html: emailHtml, // Send the rendered HTML,
      });
      console.log('Email sent successfully:', response.data);

      // Show success Snackbar
      setSnackbar({
        open: true,
        message: 'Email sent successfully!',
        severity: 'success',
      });


    } catch (error) {
      console.error('Error sending email:', error);

      // Show error Snackbar
      setSnackbar({
        open: true,
        message: 'Failed to send email.',
        severity: 'error',
      });

    }

    handleClose(); // Close modal after submission

  };

  return (
    <div>
      {/* Snackbar for email status */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioned at the top center
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity} 
          sx={{ 
           // width: '100%',
            }}>
          {snackbar.message}
        </Alert>

      </Snackbar>
      <Button
        variant="outlined"
        size="small"
        startIcon={<MailOutlineIcon />}
        color="success"
        onClick={handleOpen}
        sx={{ mt: 2 }}
      >
        Contact us
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} align='center'>
          <Typography id="modal-title" variant="h6" component="h2">
            Nous contacter
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
              sx={{
                input: {
                  color: 'white', // Text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#66bb6a', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#4caf50', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#388e3c', // Focused border color
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#bdbdbd', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#66bb6a', // Label color when focused
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                input: {
                  color: 'white', // Text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#66bb6a', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#4caf50', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#388e3c', // Focused border color
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#bdbdbd', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#66bb6a', // Label color when focused
                },
              }}
            />
            <TextareaAutosize
              minRows={12}
              placeholder="Laissez nous un message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              style={{
                width: '100%', // Full width
                color: 'white', // Text color
                backgroundColor: '#282f34', // Background color matching the modal
                borderColor: '#66bb6a', // Default border color (green)
                borderRadius: '4px', // Rounded corners
                padding: '8px', // Padding for the content inside
                marginTop: '16px', // Spacing on top
                outlineColor: '#388e3c', // Focused border color (green)
                border: '1px solid', // Set the default border style
                transition: 'border-color 0.3s ease', // Smooth transition for hover/focus
              }}
              onFocus={(e) => (e.target.style.borderColor = '#388e3c')} // Change border color on focus
              onBlur={(e) => (e.target.style.borderColor = '#66bb6a')} // Reset border color on blur
            />

            <Button type="submit" variant="contained" color="success" sx={{ mt: 2 }}>
              Envoyer
            </Button>
          </form>
        </Box>
      </Modal>

    </div>

  );
};

export default ModalForm;
