import React, { useEffect, useState } from 'react';
import { Grow, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const GrowTransitionComponent = () => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true); // Set to true when the component mounts
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {[...Array(5)].map((_, index) => (
        <Grow key={index} in={inProp} timeout={1000 + index * 200}>
          <StarIcon sx={{ color: "#ffc400", margin: '0 4px' }} />
        </Grow>
      ))}
    </Box>
  );
};

export default GrowTransitionComponent;
