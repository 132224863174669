// EmailTemplate.tsx
import React from 'react';

interface EmailTemplateProps {
  name: string;
  email: string;
  message: string;
}

const EmailTemplate: React.FC<EmailTemplateProps> = ({ name, email, message }) => {
  return (
    <html>
      <head>
        <style>
          {`
            body {
              font-family: Arial, sans-serif;
              color: #333;
            }
            .container {
              padding: 20px;
              border: 1px solid #e0e0e0;
              background-color: #f9f9f9;
            }
            .footer {
              margin-top: 20px;
              font-size: 12px;
              color: #888;
            }
          `}
        </style>
      </head>
      <body>
        <div className="container">
          <h1>New message from {name}</h1>
          <p>{message}</p>
          <p>Email sent from: <a href={`mailto:${email}?subject=Achat du domain crazylou.fr`}>{email}</a></p>
        </div>
      </body>
    </html>
  );
};

export default EmailTemplate;
